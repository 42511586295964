import {
  START_CALL,
  NEXT_CALL,
  COMPLETE_CURRENT,
  UPDATE_EXAMINATION,
  UPDATE_RECOMMENDATIONS,
} from "actions/calls/types";

/**
 * @param state
 * @param action
 */
const calls = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case START_CALL:
    case NEXT_CALL:
      return { ...state, ...action.payload.data };
    case UPDATE_EXAMINATION:
    case UPDATE_RECOMMENDATIONS:
      return { ...state, appointment: action.payload,
        currentAppointment: {...state.currentAppointment, ...action.payload} };
    case COMPLETE_CURRENT:
      return { ...state, ...action.payload.data };
    // return { ...state, appointment: action.payload };
    default:
      return state;
  }
};

export default calls;
