export const FETCH_UTILITIES = "FETCH_UTILITIES";
export const CREATE_USER = "CREATE_USER";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const FETCH_USERS = "FETCH_USERS";
export const DELETE_USER = "DELETE_USER";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const ASSIGN_USER_TO_HOSPITALS = "ASSIGN_USER_TO_HOSPITALS";
export const GET_ASSIGNED_HOSPITALS = "GET_ASSIGNED_HOSPITALS";
export const GET_USER_DASHBOARD = "GET_USER_DASHBOARD";
