// import { initializeApp } from "firebase/app";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyA2Lnj_luThIgfa6S55VxPgqMqE7IXXOes",
//   authDomain: "virtual-hospital-1.firebaseapp.com",
//   projectId: "virtual-hospital-1",
//   storageBucket: "virtual-hospital-1.appspot.com",
//   messagingSenderId: "442711782451",
//   appId: "1:442711782451:web:59bfd101c6300254569f46"
// };

// const firebaseApp = initializeApp(firebaseConfig);

// export default firebaseApp;

import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCL6m-FpTrTVN2roR_Xqx4sLUcWrPNrcSE",
  authDomain: "virtual-hospital-dc044.firebaseapp.com",
  projectId: "virtual-hospital-dc044",
  storageBucket: "virtual-hospital-dc044.appspot.com",
  messagingSenderId: "579029254113",
  appId: "1:579029254113:web:6e7e72c9eee66d4928dca0",
  measurementId: "G-F739W70BMW"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
const messaging = getMessaging(app);
export default messaging;

