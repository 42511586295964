import { combineReducers } from "redux";
import appointments from "./appointments/appointmentReducer";
import MatchedSentSurveys from "./matched-sent-surveys/matchedSentSurveysReducer";
import appointmentUtilities from "./appointments/appointmentUtilities";
import users, { utilities } from "./users/userReducer";
import roles, { permissions } from "./roles/roleReducer";
import hospitals from "./hospitals/hospitalReducer";
import levels from "./levels/levelReducer";
import labs from "./labs/labReducer";
import radiologyCenters from "./radiology-centers/radiologyCentersReducer";
import specialties from "./specialties/specialtyReducer";
import error from "./error/errorReducer";
import auth from "./auth/authReducer";
import prescriptions, {
  prescriptionUtilities,
} from "./prescriptions/prescriptionReducer";
import episodes, { episodeUtilities } from "./episodes/episodeReducer";
import patients, {surveyUtilities} from "./patients/patientReducer";
import cities from "./cities/cityReducer";
import countries from "./countries/countryReducer";

import vitalSigns, {
  vitalSignUtilities,
} from "./vital-signs/vitalSignsReducer";
import specialtiesForm, {
  specialtiesFormUtilities,
} from "./specialties-form/specialtiesFormReducer";
import followUps, { followUpUtilities } from "./follow-ups/followUpsReducer";
import investigation from "./episodes/investigationReducer";
import language from "./language/languageReducers";
import medicalHistory from "./episodes/medicalHistoryReducer";
import pastMedicalHistory from "./past-medical-history/pastMedicalHistoryReducer";
import dischargeForm from "./discharge-form/dischargeFormReducer";
import prescriptionExecutions from "./episodes/prescriptionExecutionReducer";
import payments from "./payments/paymentReducers";
import clinics, { clinicsUtilities } from "./clinics/clinicsReducer";
import clinicStart from "./clinics/clinicStartReducer";
import doctors, { consultantDoctors } from "./doctors/doctorsReducer";
import patientAppointments, {
  patientClinicSearch,
  patientAppointmentSelection,
  patientClinicSlots,
  patientAppointmentLobbyCheck,
  patientAppointmentAttachments,
  patientRoomData,
	patientTimeLine
} from "./appointments/patientAppointmentReducer";
import vacations from "./vacations/vacationsReducer";
import lobby from "./lobby/lobbyReducer";
import calls from "./calls/callReducer";
import coordinators from "./coordinators/coordinatorsReducer";
import rounds, { roundsUtilities } from "./rounds/rounds";
import requests from "./requests/requestReducer";
import calendarEvents, { calendarUtilities } from "./calendar/EventsReducer";
import notifications from "./notifications/notificationstReducer";
import icd from "./icd/icd";
import communications from "./communications/communicationsReducer";
import commonUtilities from "./commonUtilities/commonUtilitiesReducer";
import sidebarShow from "./sidebarShow/sideBarShowReducer";
import questions from "./surveys/questionsReducer";
import reports,{report} from "./reports/reportReducer";
import sentSurveys from "./sent-surveys/sentSurveysReducer";
import firebase from "./firebase/firebaseReducer";

const rootReducer: Object = combineReducers({
  appointments,
  appointmentUtilities,
  users,
  utilities,
  permissions,
  roles,
  hospitals,
  levels,
  labs,
  radiologyCenters,
  specialties,
  error,
  auth,
  prescriptions,
  prescriptionUtilities,
  episodes,
  episodeUtilities,
  patients,
  surveyUtilities,
  cities,
  countries,
  vitalSigns,
  vitalSignUtilities,
  medicalHistory,
  specialtiesForm,
  specialtiesFormUtilities,
  followUps,
  followUpUtilities,
  investigation,
  language,
  pastMedicalHistory,
  dischargeForm,
  prescriptionExecutions,
  payments,
  clinics,
  clinicsUtilities,
  doctors,
  patientAppointments,
  patientClinicSearch,
  vacations,
  patientAppointmentSelection,
  patientClinicSlots,
  clinicStart,
  patientAppointmentLobbyCheck,
  patientAppointmentAttachments,
  lobby,
  calls,
  patientRoomData,
  coordinators,
  rounds,
  roundsUtilities,
  requests,
  calendarEvents,
  calendarUtilities,
  notifications,
  icd,
  communications,
  commonUtilities,
  sidebarShow,
  questions,
  reports,
  MatchedSentSurveys,
  sentSurveys,
  consultantDoctors,
  report,
	firebase,
	patientTimeLine
});

export default rootReducer;
