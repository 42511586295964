import { REGISTER_FIREBASE_TOKEN, REMOVE_FIREBASE_TOKEN } from "actions/firebase/types";

/**
 * @param state
 * @param action
 */
const firebase = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case REGISTER_FIREBASE_TOKEN:
      return { ...state, ...action.payload };
    case REMOVE_FIREBASE_TOKEN:
      return { };
    default:
      return state;
  }
};

export default firebase;
